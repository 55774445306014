var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.$route.path.indexOf('graphical') < 1 &&
      _vm.$route.path.indexOf('notify') < 1) ||
    _vm.$route.path.indexOf('notify-template')
  )?_c('div',{staticClass:"header-menu no-print"},[_c('div',{staticClass:"container-fluid"},[_c('ul',{staticClass:"nav"},[(_vm.$route.path.indexOf('report') === 1)?[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Statistics' }}},[_vm._v(_vm._s(_vm.$t("__reportStatistics")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'SearchReportResident' }}},[_vm._v(_vm._s(_vm.$t("__reportResident")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ReportNotifyRecord' }}},[_vm._v(_vm._s(_vm.$t("__notifyReport")))])],1)]:_vm._e(),(_vm.$route.path.indexOf('device-management') === 1)?[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'DeviceResident' }}},[_vm._v(_vm._s(_vm.$t("__residentDevice")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ResidentManagement' }}},[_vm._v(_vm._s(_vm.$t("__residentManagement")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'NetworkManagement' }}},[_vm._v(_vm._s(_vm.$t("__networkManagement")))])],1)]:_vm._e(),(
          _vm.$route.path.indexOf('notify-template') === 1 &&
          _vm.notifyTemplate &&
          _vm.notifyTemplate.length
        )?[_vm._l((_vm.notifyTemplate),function(template){return _c('li',{key:template.id,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
              name: 'NotifyTemplate',
              params: { id: template.id },
            }}},[_vm._v(_vm._s(!template.is_template ? template.name : _vm.$t("__" + template.name))+" ")])],1)}),(_vm.notifyTemplate.length < 5)?_c('li',{staticClass:"nav-item"},[_c('button',{staticClass:"btn btn-link nav-link border-0",attrs:{"type":"button"},on:{"click":_vm.addNotifyTemplate}},[_c('i',{staticClass:"bi bi-plus-circle-fill"})])]):_vm._e()]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }